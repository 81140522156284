.who-can-join-content {
	@media (min-width: 992px) {
		max-width: 660px;
	}
	.title {
		margin-bottom: 20px;
		font-weight: 600;
	}
	p {
		&:not(:last-child) {
			margin-bottom: 35px;
		}
		line-height: 1.7;
	}
}
.who-can-join-info {
	list-style: none;
	padding: 0;
	margin: 35px 0 0;
	display: flex;
	flex-wrap: wrap;
	li {
		width: 100%;
		// max-width: 260px;
		color: var(--title);
		margin-bottom: 12px;
		display: flex;
		
		
		svg {
			color: var(--base);
			font-size: 18px;
			margin-right: 8px;
			height: 1rem;
			width: 3rem;
		}
		
	}
	.who-can-join-info-text{
	
	}
	@media (max-width: 440px) {
		li {
			width: 100%;
		}
	}
}
.who-can-join-nav {
	position: sticky;
	top: 74px;
	left: 0;
	padding: 10px 0;
	background: var(--white);
	z-index: 9;
	@media (max-width: 991px) {
		top: 72px;
	}
	ul {
		li {
			.nav-link {
				box-shadow: 0 5px 15px rgba(0, 85, 159, 0.25);
				display: flex;
				align-items: center;
				padding: 12px 25px;
				color: var(--title);
				justify-content: center;
				img {
					width: 24px;
					height: 24px;
					object-fit: contain;
					margin-right: 8px;
				}
				font-weight: 500;
				text-transform: capitalize;
				border-radius: 0 !important;
				margin: 5px;
			}
			&.active {
				.nav-link {
					color: var(--white) !important;
					background: var(--title) !important;
					img {
						filter: brightness(10);
					}
				}
			}
			position: relative;
			.click-spy {
				position: absolute;
				inset: 0;
				z-index: 1;
			}
			@media (max-width: 991px) {
				@media (min-width: 576px) {
					flex-grow: 1;
				}
				.nav-link {
					font-size: 12px;
					padding: 12px 15px;
					img {
						width: 24px;
						height: 24px;
					}
				}
			}
			@media (max-width: 575px) {
				.nav-link {
					font-size: 12px;
					padding: 8px 15px;
					img {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
}
.who-can-join-img {
	padding-left: 20px;
	padding-top: 20px;
	border-radius: 200px 0 0 0;
	overflow: hidden;
	position: relative;
	&::before {
		inset: 0 20px 20px 0;
		position: absolute;
		content: "";
		background: var(--base);
		z-index: -1;
	}
	img {
		border-radius: 180px 0 0 0;
	}
}
.pt-80 {
	padding-top: 80px
}