.training-programe-details {
  .top-img {
    img {
      width: 100%;
      max-height: 465px;
      object-fit: cover;
    }
    .event-meta {
      font-size: 14px;
      svg {
        font-size: 17px;
        color: var(--base);
        margin-right: 5px;
      }
      span {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
      margin-top: 25px;
    }
  }
  .title {
    margin: 0;
    margin-bottom: 18px;
  }
}
.visual-list {
  font-size: 30px;
  color: var(--base);
  h6 {
    margin: 0;
    margin-bottom: 5px;
  }
  div {
    color: var(--text);
    font-size: 14px;
  }
  li {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
.tags-area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .tags,
  .share {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5px;
    h6 {
      margin: 0;
      margin-right: 10px;
    }
  }
  .tags {
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        &:not(:last-child) {
          margin-right: 10px;
          &::after {
            content: ',';
          }
        }
      }
    }
  }
}
.comments-list-items {
  padding: 0;
  margin: 0;
  margin-top: 30px;
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    &:not(:last-child) {
      padding-bottom: 30px;
      border-bottom: 1px solid #f4f4f4;
    }
    &:not(:first-child) {
      padding-top: 30px;
    }
    img {
      width: 80px;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 50%;
    }
    .cont {
      width: calc(100% - 80px);
      padding-left: 20px;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;
      .cmn--btn {
        padding: 8px 15px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }
      .name {
        font-size: 18px;
        margin-bottom: 5px;
      }
      .date {
        font-size: 14px;
        margin-bottom: 10px;
        display: block;
      }
    }
    @media (max-width: 425px) {
      font-size: 14px;
      img {
        width: 30px;
      }
      .cont {
        padding-left: 10px;
        width: calc(100% - 30px);
        .name {
          font-size: 15px;
        }
        .date {
          font-size: 12px;
        }
        .cmn--btn {
          font-size: 12px;
        }
      }
    }
  }
}
.comment-title {
  @media (min-width: 576px) {
    font-size: 26px;
    font-weight: 600;
  }
}
textarea.form-control {
  height: 130px;
}
.live-class .nav-link {
  font-size: 1.1rem !important;
  color: black !important;
}
.live-class .nav-link.active {
  color: var(--base) !important;
}
