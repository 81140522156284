a {
  text-decoration: none;
}

/* input {
	border: none;
	background-color: rgba(0,0,0,0);
} */

::selection {
  background-color: #d4e9ff;
}

.epub-highlight {
  fill-opacity: 0.3;
  mix-blend-mode: multiply;
  cursor: pointer;
  pointer-events: fill;
  transition: 0.1s ease-in-out;
}

.epub-highlight:hover {
  fill-opacity: 0.4;
}
