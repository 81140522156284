.form-control {
  height: 55px;
  background-color: #f4f6f9;
  border: 1px solid transparent;
  box-shadow: none !important;
  outline: none;

  &:focus {
    border-color: var(--base);
  }
}

.contac-wrapper {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px 3px #1414450f;

  .title {
    margin-bottom: 15px;
    font-weight: 600;
  }

  .form-label {
    text-transform: capitalize;
    color: var(--title);
    font-size: 16px;
  }

  textarea.form-control {
    min-height: 180px;
  }

  @media (min-width: 1400px) {
    padding-top: 40px;
  }
}

.cmn--btn.form-control {
  background: var(--base);
  color: var(--white);
}

.contact__card {
  text-align: center;
  padding: 30px 15px;
  background-color: #f4f6f9;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #f4f4f4;

  .icon {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: var(--base);
    border: 1px solid #00bee3;
    border-radius: 50%;
    margin: 0 auto 26px;
  }

  .content {
    .info {
      display: block;
    }

    .subtitle {
      margin-bottom: 13px;
    }

    a {
      color: var(--base);
    }
  }
}

label {
  a {
    color: var(--base);
  }
}

.contact-hr {
  border-color: #00000040;
  margin: 25px 0;

  @media screen and (min-width: 1300px) {
    margin: 25px 0;
  }
}

.contact-social {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  li {
    padding: 4px;
    margin: 0;
    list-style: none;

    a {
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #eeeeee;
      color: var(--base);

      &:hover {
        background: var(--base);
        color: var(--white);
      }
    }
  }
}

.contact-us {
  p {
    max-width: 580px;
  }

  .contact-form {
    border-style: solid;
    border-color: #00bee3;
    border-radius: 10px;
    border-width: 2px;

    .title {
      border-style: solid;
      border-color: red;
      border-width: 2px;
    }
  }
}
