.privacy-section {
	p {
		margin: 0;
		line-height: 1.6;
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
	.subtitle {
		margin-bottom: 20px;
	}
	ul {
		li {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
}
.terms-section{
	ol{
		list-style-type: none;
		counter-reset: item;
		margin: 0;
		padding: 0;

		li{
			display: table;
			counter-increment: item;
			margin-bottom: 0.6em;

			

			&::before {
				content: counters(item, ".") ". ";
  				display: table-cell;
 		 		padding-right: 0.6em;	
				font-size: 22px;
				color: #111D5E;
				font-weight: 600;
			}

			p{
				margin: 10px 0 !important;
			}

			ul{
				list-style-type: disc !important;
				counter-reset: item;
				margin: 0;
				padding: 0;
				li{
					&::before {
						content: '•';			
					}
				}
			}

			ol{
				li{
					margin: 0;
					margin-top: 5px;

					&::before {
						content: counters(item, ".") " ";			
					}

					ul{
						list-style-type: disc !important;
						margin: 0;
						padding: 0;
						li{
							&::before {
								content: '•';			
							}
						}
					}

					p{
						margin: 10px 0 !important;
					}
				}
			}
		}
	}
	

}
