.text-theme{
    color: var(--base) !important;
}
.bg_blue{
    background: var(--base) !important;;
}
.fixwidth{
    width: 45px;
}
.scroll{
    height: 500px;
    overflow: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar{
        width: 5px;
    }
    
    &::-webkit-scrollbar-thumb{
        background: linear-gradient(transparent, #00bde2);
        border-radius: 5px;
    }
}
.pointer{
    cursor: pointer;
}
.bg_purple{
    background: #d7d7f3;
}
.bg_light_blue {
    background-color: #e5f8fc !important;
  }
.box_shadow2 {
    box-shadow: 0px 4px 20px rgb(54 121 165 / 30%) !important;
  }
  