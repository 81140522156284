.learning-content {
	@media (min-width: 992px) {
		max-width: 639px;
	}
	.title {
		font-weight: 600;
		margin-bottom: 30px;
	}
	p {
		&:not(:last-child) {
			margin-bottom: 35px;
		}
	}
	@media (max-width: 575px) {
		.title {
			margin-bottom: 20px;
		}
		p {
			&:not(:last-child) {
				margin-bottom: 25px;
			}
		}
	}
}
