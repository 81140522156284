.choose__card {
	&-img {
		width: 97px;
		height: 97px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-width: 100%;
		}
		border-radius: 50%;
		background: #00bee32b;
		margin: 0 auto 25px;
	}
	text-align: center;
	&-cont {
		.title {
			margin-bottom: 20px;
		}
	}
	padding: 48px 15px 75px;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	border: 1px solid transparent;
	transition: all ease 0.3s;
	border-radius: 15px;
	p {
		max-width: 231px;
		margin-left: auto;
		margin-right: auto;
	}
	&:hover,
	&.active {
		background: var(--white);
		box-shadow: 10px 10px 34px rgba(0, 85, 159, 0.071);
		border-color: #f4f4f4;
	}
	@media (max-width: 1199px) {
		padding: 25px 15px;
	}
}
.about-image {
	position: relative;
	padding-right: 122px;
	padding-bottom: 70px;
	.about-img {
		img {
			width: 100%;
		}
		position: relative;
		padding-left: 20px;
		padding-top: 20px;
		border-radius: 200px 0 0 0;
		overflow: hidden;
		position: relative;
		&::before {
			inset: 0 20px 20px 0;
			position: absolute;
			content: "";
			background: var(--base);
			z-index: -1;
		}
		img {
			border-radius: 180px 0 0 0;
		}
	}
	.type-txt {
		position: absolute;
		top: 15%;
		right: 50px;
		width: 150px;
		height: 150px;
		z-index: 1;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			position: absolute;
		}
		h4 {
			font-size: 18px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
			padding: 0;
			padding-left: 5px;
		}
	}
	.border-img {
		position: absolute;
		bottom: 0;
		right: 0;
		border-radius: 0 0 90px 0;
		border: 10px solid var(--white);
		@media (max-width: 1399px) {
			width: 260px;
			border-width: 7px;
		}
	}
	@media (max-width: 1199px) {
		.about-img {
			@media (min-width: 992px) {
				padding-left: 10px;
				padding-top: 10px;
				border-radius: 180px 0 0 0;
			}
		}
	}
	@media (max-width: 767px) {
		.about-img {
			padding-left: 10px;
			padding-top: 10px;
			border-radius: 180px 0 0 0;
		}
	}
	@media (max-width: 575px) {
		.about-img {
			padding-left: 10px;
			padding-top: 10px;
			border-radius: 180px 0 0 0;
		}
		.border-img {
			width: 230px;
			border-right: 0;
		}
		.type-txt {
			width: 120px;
		}
	}
	@media (max-width: 450px) {
		padding-right: 70px;
		.about-img {
			border-radius: 140px 0 0 0;
			img {
				border-radius: 130px 0 0 0;
			}
		}
		.border-img {
			width: 160px;
		}
	}
	@media (max-width: 400px) {
		padding-right: 40px;
		.about-img {
			border-radius: 140px 0 0 0;
			img {
				border-radius: 130px 0 0 0;
			}
		}
		.type-txt {
			right: 0;
		}
		.border-img {
			width: 130px;
		}
	}
}
