.main_section {
  h1 {
    font-size: 100px !important;
    font-weight: 100;
    color: black;
    letter-spacing: 4px;
  }
  p {
    font-size: 20px;
  }
  button {
    font-size: 15px;
  }
}
